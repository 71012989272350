import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import styled from 'styled-components'

const notFound = () => {
  return (
    <CustomThemeProvider>
      <Layout>
        <Seo title="404: Not found"/>
        <MainWrapper>
          <h2>404</h2>
          <p>Sorry, that page doesn't exist</p>
        </MainWrapper>
      </Layout>
    </CustomThemeProvider>
  )
}

export default notFound


const MainWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  gap:2rem;
  padding-top:20%;
  padding-bottom:10%;
`

